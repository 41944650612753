import React from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_control-backups.js";

const ContentPage = () => (
  <Layout.Layout textColor="dark">
    <Layout.SEO
      refKey="supabase"
      title="Supabase Backup Service, simple and automated"
      description="Automate your Supabase backup and store them on any cloud provider."
    />
    <Layout.Hero
      type="primary"
      title={(<><span className="text-primary">Supabase Backup</span> Service, simple and automated</>)}
      subTitle={(
        <>Create automatic Supabase backups without code.<br/>
          Set a schedule, a storage and let it work in peace.
        </>)}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Automate your Supabase Backup" urlAtrr={{ sb_source: "website", sb_term: "supabase-hero" }} />)}
      illustration={(
        <Ui.Image className="" filename="simplebackups-postgresql-backup-hero.png" alt="SimpleBackups Supabase PostgreSQL backup"/>
      )}
      withIllustrationBox={false}
    />

    <Ui.Container>
      <Sections.JoinBrandsSection />
    </Ui.Container>

    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              {
                preTitle: "Autoamte your backups",
                title: (<>Automated Supabase backups. Simply.</>),
                description: (
                  <>
                    <p>
                      Automate and schedule your Supabase PostgreSQL backups as needed.
                      Set up a schedule for your PostgreSQL backups, ensuring regular, timely backups without manual intervention.
                      Tailor the backup frequency to your specific requirements, be it:
                      <Ui.FeatureChecks items={['Hourly', 'Daily', 'Weekly', 'Monthly', 'Custom Interval', 'Cron Syntax']} />
                    </p>
                  </>
                ),
                image: ( <Ui.Image
                  className="shadow-2xl rounded-xl"
                  filename='simplebackups-app-backup-expert.png'
                  alt="PostgreSQL database backup"
                  style={{maxWidth: 450}}
                />)
              },
              Content_FeatureTrust({subject: 'Supabase databases', keyRef: 'postgresql'}),
              Content_FeatureControl({subject: 'Supabase', keyRef: 'postgresql'}),
            ]}></Ui.FeatureRows>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">A glimpse of features we know you'll love!</Ui.Typography>

          <div className="grid md:grid-cols-3 md:gap-8 gap-4 text-left mt-16">
            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-binary-lock text-primary text-4xl mb-4" />
              <h3 className="text-xl font-bold mb-1 h-16 block">Encrypted Supabase Backups</h3>
              <p className="text-base">
                Ensure that your sensitive <span className="link-highlight">database information remains protected</span>, safeguarding it against unauthorized access during storage and transfer.</p>
            </div>

            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-list-check text-primary text-4xl mb-4" />
              <h3 className="text-xl font-bold mb-1 h-16 block">Selective or Complete Database Backups</h3>
              <p className="text-base">You can either back up all your Supabase databases at once or <span className="link-highlight">select a specific database for backup</span>, catering to diverse backup strategies and requirements.</p>
            </div>

            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-arrow-rotate-left text-primary text-4xl mb-4" />
              <h3 className="text-xl font-bold mb-1 h-16 block">Single Command Supabase Restoration</h3>
              <p className="text-base">Simplify the restoration process with the ability to <span className="link-highlight">restore your Supabase backups using just a single command</span>. Quickly recover your data, minimizing downtime in case of data loss.</p>
            </div>
          </div>


          <div className="mt-16">
            <Ui.Typography tag="h3" className="text-2xl text-center mb-8">And for all your backups... there's even more...</Ui.Typography>
            <Sections.FeatureSlider tags={["postgresql"]} />
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Sections.SectionLearn
      title={<>Wanna learn more about Supabase Backups?</>}
      text="Find out more and get started with our Supabase Backup guides"
      tags={['supabase']}
    />

    <Sections.SectionGetStarted/>
    <Sections.SectionTestimonialExtended />
    <Sections.SectionFaq color="alt" faqs={['supabase_what_is', 'supabase_backup_service', 'how_restore_backup', 'can_download_backup']}/>


  </Layout.Layout>
)

export default ContentPage
